
import { initializeApp } from "firebase/app";
import { getAuth } from 'firebase/auth';
import "firebase/firestore";
import { getFirestore } from "firebase/firestore";
import { getStorage } from 'firebase/storage'
import "firebase/storage";



const firebaseConfig = {
  apiKey: "AIzaSyAl_SnQtbfOUB2J6ZG5X39e2tBGAusbJpo",
  authDomain: "ms-shree-krishna-jewellers.firebaseapp.com",
  projectId: "ms-shree-krishna-jewellers",
  storageBucket: "ms-shree-krishna-jewellers.appspot.com",
  messagingSenderId: "923075860994",
  appId: "1:923075860994:web:69ea1599c64f997282a51b",
  measurementId: "G-JK239DJVD1"
 
};
//init firebase app
const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);
export const auth = getAuth(app);
export const db = getFirestore(app);




export default app;

import './App.css';
import {BrowserRouter as Router, Routes, Route } from 'react-router-dom';



import Main from "./components/pages/Main";
import Contact from "./components/pages/Contact";
import Bala from "./components/pages/Bala";
import Dul from "./components/pages/Dul";
import Ring from "./components/pages/Ring";
import Pola from "./components/pages/Pola";
import Jhumka from "./components/pages/Jhumka";
import Chain from "./components/pages/Chain";
import Sankha from "./components/pages/Sankha";
import Necklace from "./components/pages/Necklace";
import Mangalsutra from "./components/pages/Mangalsutra";
import Bangles from "./components/pages/Bangles";
import Aboutus from "./components/pages/Aboutus";

import Footer from "./components/inc/Footer";

function App() {
  return (
    <Router>
      
       <div>
      
         
          
          <Routes>
     
         
          <Route axact path="/" element={<Main/>}/>
          <Route axact path="/contact" element={<Contact/>}/>
          <Route axact path="/bala" element={<Bala/>}/>
          <Route axact path="/earring" element={<Dul/>}/>
          <Route axact path="/rings" element={<Ring/>}/>
          <Route axact path="/jhumka" element={<Jhumka/>}/>
          <Route axact path="/chains" element={<Chain/>}/>
          <Route axact path="/pola" element={<Pola/>}/>
          <Route axact path="/Necklace" element={<Necklace/>}/>
          <Route axact path="/Mangalsutra" element={<Mangalsutra/>}/>
          <Route axact path="/sankha" element={<Sankha/>}/>
          <Route axact path="/Bangles" element={<Bangles/>}/>
          <Route axact path="/Aboutus" element={<Aboutus/>}/>
          
    </Routes>
    <Footer/>
  
    </div>
   
    </Router>
   
  
   
  );
}

export default App;

import React from "react";
import Navbar from "../inc/Navbar";
import Slider from "../inc/Slider";
import msshreekrishna from "../images/sreekrishnajewellers.jpg";
import ScrollToTop from "react-scroll-to-top";
import Carousal from "../pages/Carousal";
import "./Main.css";

function Main (){
    return(
            <>
            <Navbar/>
            <br/>
            <br/>
        

            <div className="album py-0">

<div className="container">

    <div className="row">
        <h6>Jewellers Karimpur Nadia</h6>
    </div>
    </div>
    </div>

            <Slider/>
            <Carousal/>
            <br/>
            <ScrollToTop smooth top="100" color="maroon" />
            <div className="album py-2">

<div className="container">

    <div className="row">
                <div className="mssreekrishna">
                <img src={msshreekrishna} className="img-fluid"  alt="ms-sree-krishna-jewellers"/>
                </div>
          </div>
          </div>
          </div>
          <div className="containerse">
            <center>
            <div className="sreekrishnajewellerskarimpur">
                        <h1><b>MS SREE KRISHNA JEWELLERS</b></h1><br/>
                        </div>
                        <div className="rings-karimpur">
                        <h3>Best Jewellery Collections</h3>
                        </div>
                        </center>
                    </div>

          
            
            </>

);
}

export default Main
import React from "react";
import Showroomjewellers from "../images/showroom.jpg";
import Showroomsree from "../images/showroomsreekrishna.jpg";
import Showrooms from "../images/showrroms.jpg";

function Slider2 (){
    return(
            <>


<div id="carouselExampleControls" className="carousel slide" data-bs-ride="carousel">
  <div className="carousel-inner">
    <div className="carousel-item active">
      <img src={Showroomjewellers} className="d-block w-100" alt="Showroomjewellers"/>
    </div>
    <div className="carousel-item">
      <img src={Showroomsree} className="d-block w-100" alt="Showroomsree"/>
    </div>
    <div className="carousel-item">
      <img src={Showrooms} className="d-block w-100" alt="mssreekrishnajewellers"/>
    </div>
  </div>
  <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Previous</span>
  </button>
  <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
    <span className="carousel-control-next-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Next</span>
  </button>
</div>
            </>


    );
}

export default Slider2;
import React from 'react'
import Slider from "react-slick";

import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

import Bala from "../images/bala.jpg";
import Necklece from "../images/chains.jpg";
import Pola from "../images/pola.jpg";
import Ring from "../images/rings.jpg";
// import Chain from "../images/chains.jpg";
import Dulsreekrishna from "../images/dul.jpg";
import Necklace from "../images/necklace.jpg";
import Jhumka from "../images/jhumka.jpg";
import Mongalsutra from "../images/mangolsutra.jpg";
import Sankha from "../images/sankha.jpg";
import Bangles from "../images/bangles.jpg";


import "./Carousal.css";

import { Link } from 'react-router-dom';


function Silck() {

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3
      };

  return (
    <div>
       
       
        <div className="animat">
        <Slider {...settings}>

         

          <div>
          <div className="card mb-4 border-White box ">
                <Link to="/pola">
                        <img src={Pola} className="card-img-top"   alt="Pola" />
                        </Link>
                            <div className="card-body">
                                
                                <p style={{color: "maroon"}} className="card-text mb-0">Pola</p>
                                <Link to="/pola">
                                <p className="card-text"> <small> <span>Explore<svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
    </svg></span></small></p> </Link>
                                </div>
                                </div>
          </div>

          <div>
             <div className="card mb-4 border-White box ">
                <Link to="/rings">
                        <img src={Ring} className="card-img-top"   alt="Ring" />
                        </Link>
                            <div className="card-body">
                                
                                <p style={{color: "maroon"}} className="card-text mb-0">Ring</p>
                                <Link to="/rings">
                                <p className="card-text"> <small> <span>Explore<svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
    </svg></span></small></p> </Link>
                                </div>
                                </div>
          </div>

          <div>
          <div className="card mb-4 border-White box ">
                <Link to="/jhumka">
                        <img src={Jhumka} className="card-img-top"   alt="mbdharandsonjewellers" />
                        </Link>
                            <div className="card-body">
                                
                                <p style={{color: "maroon"}} className="card-text mb-0">Jhumka</p>
                                <Link to="/jhumka">
                                <p className="card-text"> <small> <span>Explore<svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
    </svg></span></small></p> </Link>
                                </div>
                                </div>
          </div>

          <div>
          <div className="card mb-4 border-White box ">
                <Link to="/chains">
                        <img src={Necklece} className="card-img-top"   alt="Nacklece" />
                        </Link>
                            <div className="card-body">
                                
                                <p style={{color: "maroon"}} className="card-text mb-0"><small>Chains</small></p>
                                <Link to="/chains">
                                <p className="card-text"> <small> <span>Explore<svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
    </svg></span></small></p> </Link>
                                </div>
                                </div>
          </div>

          
          <div className="card mb-4 border-White box ">
                <Link to="/Necklace">
                        <img src={Necklace} className="card-img-top"   alt="Necklace" />
                        </Link>
                            <div className="card-body">
                                
                                <p style={{color: "maroon"}} className="card-text mb-0"><small>Necklace</small></p>
                                <Link to="/Necklace">
                                <p className="card-text"> <small> <span>Explore<svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
    </svg></span></small></p> </Link>
                                </div>
                                </div>

                                <div className="card mb-4 border-White box ">
                <Link to="/earring">
                        <img src={Dulsreekrishna} className="card-img-top"   alt="Dulsreekrishna" />
                        </Link>
                            <div className="card-body">
                                
                                <p style={{color: "maroon"}} className="card-text mb-0"><small>Earring</small></p>
                                <Link to="/earring">
                                <p className="card-text"> <small> <span>Explore<svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
    </svg></span></small></p> </Link>
                                </div>
                                </div>



                                <div className="card mb-4 border-White box ">
                <Link to="/bala">
                        <img src={Bala} className="card-img-top"   alt="Dulsreekrishna" />
                        </Link>
                            <div className="card-body">
                                
                                <p style={{color: "maroon"}} className="card-text mb-0"><small>Bala</small></p>
                                <Link to="/bala">
                                <p className="card-text"> <small> <span>Explore<svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
    </svg></span></small></p> </Link>
                                </div>
                                </div>
                                <div className="card mb-4 border-White box ">
                <Link to="/sankha">
                        <img src={Sankha} className="card-img-top"   alt="Dulsreekrishna" />
                        </Link>
                            <div className="card-body">
                                
                                <p style={{color: "maroon"}} className="card-text mb-0"><small>Sankha</small></p>
                                <Link to="/sankha">
                                <p className="card-text"> <small> <span>Explore<svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
    </svg></span></small></p> </Link>
                                </div>
                                </div>
                                <div className="card mb-4 border-White box ">
                <Link to="/Bangles">
                        <img src={Bangles} className="card-img-top"   alt="Dulsreekrishna" />
                        </Link>
                            <div className="card-body">
                                
                                <p style={{color: "maroon"}} className="card-text mb-0"><small>Bangles</small></p>
                                <Link to="/Bangles">
                                <p className="card-text"> <small> <span>Explore<svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
    </svg></span></small></p> </Link>
                                </div>
                                </div>


                                <div className="card mb-4 border-White box ">
                <Link to="/Mangalsutra">
                        <img src={Mongalsutra} className="card-img-top"   alt="Mangalsutra"/>
                        </Link>
                            <div className="card-body">
                                
                                <p style={{color: "maroon"}} className="card-text mb-0"><small>Mangal</small></p>
                                <Link to="/Mangalsutra">
                                <p className="card-text"> <small> <span>Explore<svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
    </svg></span></small></p> </Link>
                                </div>
                                </div>
                        
                          
          

          <div>
       
                                
          </div>
        
                               
                           
          <div>
       
                                
        
          </div>
          

        </Slider>
        </div>
     
    </div>
  )
}



export default Silck
